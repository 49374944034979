<template>
  <v-col cols="12">
    <v-card>
      <v-card-title>
        <h3>Reportes</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4" class="s-col-form">
            <v-radio-group dense row @change="changeTypeOption" v-model="obj.TypeOption" :disabled="obj.OptID > 0" hide-details :mandatory="true">
              <v-radio label="Beneficiarios por Fecha de Pago" :value="1"></v-radio>
              <v-radio label="Hijos proximos a cumplir 18 años" :value="2"></v-radio>
              <v-radio label="Hijos Trabajadores Activos" :value="3"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="3" class="s-col-form" v-show="obj.TypeOption == 1">
            <s-select-definition :def="1060" label="Planilla" v-model="obj.TypePayroll"></s-select-definition>
          </v-col>
          <v-col cols="2" class="s-col-form" v-show="obj.TypeOption == 1">
            <s-date v-model="obj.BeginDate" label="Fecha de Inicio" />
          </v-col>
          <v-col cols="2" class="s-col-form" v-show="obj.TypeOption == 1 || obj.TypeOption == 2">
            <s-date v-model="obj.EndDate" label="Fecha de Fin" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col cols="6" class="s-col-form">
              <v-btn @click="clearOption()" small text block color="danger"> <v-icon x-small left>fas fa-undo-alt</v-icon>Limpiar </v-btn>
            </v-col>
            <v-col cols="6" class="s-col-form">
              <v-btn @click="Generate()" small block color="primary "> <v-icon x-small left>fas fa-file-excel</v-icon>Generar Reporte </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import Service from "@/services/HumanResource/HouseholdAllowanceService";
export default {
  name: "ViewReportWorker",

  data: () => ({
    createTypeOption: 1,
    model: null,
    obj: {
      TypePayroll: 5,
      BeginDate: null,
      EndDate: null,
    },
  }),
  watch: {
    model() {
      this.obj.tp = this.model;
    },
  },
  methods: {
    changeTypeOption() {
      this.createTypeOption = this.obj.TypeOption;
    },
    clearOption() {
      this.obj.TypeOption = 1;
    },
    Generate() {
      var object = {
        tp: this.obj.TypeOption,
        id: this.obj.TypePayroll == undefined ? 0 : this.obj.TypePayroll,
        init: this.obj.BeginDate == undefined ? "" : this.obj.BeginDate,
        end: this.obj.EndDate == undefined ? "" : this.obj.EndDate,
      };
      if (object.tp == 1)
        if (object.id > 0 && object.init != undefined && object.end != undefined)
          Service.report(object, this.$fun.getUserID()).then((r) => {
            this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, "REPORTE_ASIGNACIONFAMILIAR");
          });
        else alert("Datos Vacios");
      else {
        if (object.tp == 2) {
          if (object.end != undefined)
            Service.report(object, this.$fun.getUserID()).then((r) => {
              this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, "REPORTE_PROXIMOSACUMPLIR18");
            });
          else alert("Datos Vacios");
        } else
          Service.report(object, this.$fun.getUserID()).then((r) => {
            this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, "REPORTE_HIJOSTRABAJADORESACTIVOS");
          });
      }
    },
    getImpresora(ruta) {
      console.log("pruebas");
      const URL_PLUGIN = "http://localhost:8000";
      if (ruta) URL_PLUGIN = ruta;
      return fetch(URL_PLUGIN + "/impresora").then((r) => r.json());
    },
  },
  created() {
    this.obj.EndDate = this.$moment().format("YYYY-MM-DD");
    this.obj.BeginDate = this.$moment().format("YYYY-MM-DD");
    this.getImpresora("");
  },
};
</script>
